import React from 'react';
import { Home } from '../components/page-wrappers';
import Helmet from 'react-helmet';

// tslint:disable no-default-export
export default () => {
  return (
    <>
      <Helmet title="Valle de Guadalupe Wine Tours">
        <link rel="canonical" href="https://www.valledeguadalupewinetours.com" />
      </Helmet>
      <Home />
    </>
  );
};
